body {
  margin: 0;
  /* overflow-x: hidden; */
}

:root {
  --background-color: #25262a;
  --background-light: #494a4e;
  --card-default: #2f3034;
  --card-light: #404247;
  --primary: #60c697;
  --secondary: #ffffff;
}

.App {
  background: var(--background-color);
}
